@font-face {
  font-family: Aeonik;
  src: url("Aeonik-Medium.e9faa912.woff2") format("woff2"), url("Aeonik-Medium.df9a910c.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

*, :before, :after {
  box-sizing: border-box;
}

:root {
  --color-background: #fffff8;
  --color-text: #0c0c28;
  --color-link: mediumblue;
  --color-link-visited: purple;
  --color-link-active: #e60000;
  --color-highlight: #f5f5ff;
  --color-input-background: white;
  --color-input-border: #ced4da;
  --color-button-background-a: #019c63;
  --color-button-background-b: #10bc5a;
  --color-button-text: white;
  --color-error-background: #fff2f4;
  --color-error-border: #ef9a9a;
  --color-success-background: #f0fff9;
  --color-success-border: #8cdcbf;
}

body {
  background-color: var(--color-background);
  color: var(--color-text);
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 1.25rem;
  line-height: 1.5;
}

a {
  color: var(--color-link);
  transition: color .3s;
}

a:visited {
  color: var(--color-link-visited);
}

a:active {
  color: var(--color-link-active);
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 3rem;
  margin-bottom: 1.5rem;
  font-family: Aeonik, sans-serif;
  font-weight: 500;
  line-height: 1.1;
}

.top-container {
  max-width: 77rem;
  margin-left: 5%;
  margin-right: 5%;
}

@media (width >= 40rem) {
  .top-container {
    margin-left: 10%;
    margin-right: 10%;
  }
}

.main {
  margin-top: 15%;
  margin-bottom: 5%;
}

.main:last-child {
  margin-bottom: 15%;
}

.main:after {
  clear: right;
  content: "";
  display: block;
}

.right-side-content {
  margin-bottom: 3rem;
  display: inline-block;
  position: relative;
}

@media (width >= 60rem) {
  .right-side-content {
    float: right;
    shape-outside: margin-box;
    border-bottom-left-radius: 6rem;
    margin-left: 6%;
  }
}

.left-side-content {
  max-width: 43rem;
}

.portrait, .animation {
  width: 200px;
  max-width: 100%;
  height: auto;
  display: block;
}

@media (width >= 77rem) {
  .portrait, .animation {
    width: 400px;
  }
}

@media (prefers-reduced-motion) {
  .animation {
    display: none;
  }
}

.footer {
  margin-top: 5%;
  margin-bottom: 15%;
}

p {
  text-wrap: pretty;
}

b {
  background-color: var(--color-highlight);
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  box-shadow: 0 0 0 .3em var(--color-highlight);
  font-weight: inherit;
  border-radius: .2em;
  margin-inline: .15ch;
}

li {
  text-wrap: pretty;
  margin-top: .5em;
  margin-bottom: .5em;
}

.name {
  text-wrap: balance;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 3rem;
}

@media (width >= 40rem) {
  .name {
    font-size: 4rem;
  }
}

.form {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.form h2 {
  margin-top: 0;
}

.form-field {
  margin-bottom: 1rem;
  position: relative;
}

.form-input {
  background-color: var(--color-input-background);
  border: 1px solid var(--color-input-border);
  color: inherit;
  font: inherit;
  border-radius: .375rem;
  width: 100%;
  padding: 2rem .75rem .75rem;
  display: block;
}

textarea.form-input {
  resize: vertical;
  min-height: 5rem;
}

.form-label {
  pointer-events: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-top: .75rem;
  padding-left: .75rem;
  padding-right: .75rem;
  font-size: .75rem;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}

.cf-turnstile {
  min-height: 65px;
  display: flex;
  overflow: auto;
}

.form-button {
  background-color: var(--color-button-background-a);
  background-image: linear-gradient(30deg, var(--color-button-background-a), var(--color-button-background-b));
  color: var(--color-button-text);
  cursor: pointer;
  font: inherit;
  text-align: center;
  border: none;
  border-radius: 2em;
  padding: .5em 2em;
  transition: filter .3s, opacity .3s;
  display: block;
}

.form[data-state="submitting"] .form-button {
  filter: grayscale();
  opacity: .7;
}

.output {
  background-color: var(--color-input-background);
  border: 1px solid var(--color-input-border);
  border-radius: .375rem;
  margin-top: 1rem;
  padding: .75rem;
  transition: background-color .3s, border-color .3s;
  display: block;
}

.output[hidden] {
  display: none;
}

.output-error {
  background-color: var(--color-error-background);
  border-color: var(--color-error-border);
}

.output-success {
  background-color: var(--color-success-background);
  border-color: var(--color-success-border);
}
/*# sourceMappingURL=frontend.c3916602.css.map */
